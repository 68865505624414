import InlineRestClient from "inline-rest-client"

//export const backendURL = 'https://backend.medfiles1.hostingasp.pl/';
//export const backendURL = 'http://localhost:5268/';
export const backendURL = 'https://backend.medfiles.pl/';

export const Api = new InlineRestClient({
    url: backendURL,
    defaultMethod: 'post',
    parameterType: 'json',
    withCredentials: true,
    headers:{'x-csrf-protection':1}
})
export const ApiMultipart = new InlineRestClient({
    url: backendURL,
    defaultMethod: 'post',
    parameterType: 'multipart',
    withCredentials: true,
    headers:{'x-csrf-protection':1}
})